import {
  FC,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useMemo,
  FormEvent,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import { WalletStore, CurrenciesStore } from "stores";
import { ModalButton, CloseModalButton } from "components/Buttons";
import { CurrencyInput, RangeInput, LimitInput } from "components/Inputs";
import TradeInfoRows from "./TradeInfoRows";
import { CurrencySelectModal } from "components/Modals";
import {
  AppText,
  // Tabs,
  Checkbox,
  Preloader,
} from "components";
import {
  normolizeDecimalsForInputs,
  normolizeCurrenciesDecimals,
} from "helpers/funcs";
import {
  ModalContent,
  ModalHeading,
  InnerContentForm,
  TransactionContainer,
  TransactionGroup,
  SwitchButton,
  ModalError,
  ModalErrorStatus,
  StyledSwitchContainer,
  StyledDivider,
  TradeInfo,
} from "../styled";
import { exceedAmount, lowerThanMinAmount } from "helpers/errorMessages";
import type { AvailableTradeOptions, MarketsInterface } from "helpers/types";
import type { OperationType, MarketsType } from "./";

import { ReactComponent as SwitchIcon } from "assets/icons/switch.svg";

// const tabs = ["MARKET", "LIMIT"];

type SelectType = "rightOptions" | "leftOptions";

interface MainTradeStepProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setSkipConfirm: Dispatch<SetStateAction<boolean>>;
  skipConfirm: boolean;
  onClose: () => void;
  leftCurrencyValue: string;
  rightCurrencyValue: string;
  leftCurrency: AvailableTradeOptions | null;
  rightCurrency: AvailableTradeOptions | null;
  setLeftCurrencyValue: Dispatch<SetStateAction<string>>;
  setRightCurrencyValue: Dispatch<SetStateAction<string>>;
  setFullLeftCurrencyValue: Dispatch<SetStateAction<string>>;
  setLeftCurrency: Dispatch<SetStateAction<AvailableTradeOptions | null>>;
  setRightCurrency: Dispatch<SetStateAction<AvailableTradeOptions | null>>;
  operationType: OperationType;
  setOperationType: Dispatch<SetStateAction<OperationType>>;
  fees: number;
  cost: number;
  tabValue: number;
  setTabValue: Dispatch<SetStateAction<number>>;
  isStatic?: boolean;
  isLoading: boolean;
  isOpen: boolean;
  currentExchangeMarket: MarketsInterface | null;
  limitPriceValue: string;
  setLimitPriceValue: Dispatch<SetStateAction<string>>;
  marketType: MarketsType;
  fullLeftCurrencyValue: string;
  setIsMaxValue: Dispatch<SetStateAction<boolean>>;
  isMaxValue: boolean;
  currentFee: number;
  setCurrentFee: Dispatch<SetStateAction<number>>;
}

const MainTradeStep: FC<MainTradeStepProps> = ({
  onSubmit,
  skipConfirm,
  setSkipConfirm,
  onClose,
  leftCurrencyValue,
  rightCurrencyValue,
  leftCurrency,
  rightCurrency,
  setLeftCurrencyValue,
  setRightCurrencyValue,
  setLeftCurrency,
  setRightCurrency,
  setFullLeftCurrencyValue,
  operationType,
  setOperationType,
  fees,
  cost,
  tabValue,
  setTabValue,
  isLoading,
  isOpen,
  isStatic,
  currentExchangeMarket,
  limitPriceValue,
  setLimitPriceValue,
  marketType,
  fullLeftCurrencyValue,
  setIsMaxValue,
  isMaxValue,
  currentFee,
  setCurrentFee,
}) => {
  const { t } = useTranslation();
  const { walletsList } = WalletStore;
  const { markets, currencies } = CurrenciesStore;

  const [hasError, setHasError] = useState<string | null>(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [selectType, setSelectType] = useState<SelectType>("leftOptions");

  const openSelect = (type: SelectType) => {
    setSelectType(type);
    setIsSelectOpen(true);
  };

  const leftCurrencyOptions = useMemo<AvailableTradeOptions[]>(() => {
    const leftOptions = markets
      ? markets.reduce((acc: AvailableTradeOptions[], el: MarketsInterface) => {
          if (
            (marketType === "limit" && el.limitOrderEnabled === false) ||
            (marketType === "market" && el.marketOrderEnabled === false)
          ) {
            return acc;
          }
          if (!acc.find((option) => option === el.leftCurrencyName)) {
            acc.push(el.leftCurrencyName);
          }
          if (!acc.find((option) => option === el.rightCurrencyName)) {
            acc.push(el.rightCurrencyName);
          }
          return acc;
        }, [])
      : [];
    return leftOptions;
  }, [markets, marketType]);

  const rightCurrencyOptions = useMemo<AvailableTradeOptions[]>(() => {
    const rightOptions = markets
      ?.filter(({ marketOrderEnabled, limitOrderEnabled }) =>
        marketType === "limit"
          ? limitOrderEnabled === true
          : marketOrderEnabled === true
      )
      ?.filter(
        ({ leftCurrencyName, rightCurrencyName }) =>
          leftCurrency === leftCurrencyName ||
          leftCurrency === rightCurrencyName
      )
      .map(({ leftCurrencyName, rightCurrencyName }) =>
        leftCurrency === leftCurrencyName ? rightCurrencyName : leftCurrencyName
      );
    return rightOptions || [];
  }, [markets, leftCurrency, marketType]);

  const leftCurrencyBalance = useMemo<number>(() => {
    const leftBalance = walletsList?.find(
      ({ currencyName }) => leftCurrency === currencyName
    )?.balance;
    return !leftCurrency || !leftBalance
      ? 0
      : Number(
          normolizeCurrenciesDecimals(leftBalance, leftCurrency, currencies)
        );
  }, [walletsList, leftCurrency, currencies]);

  const leftFullCurrencyBalance = useMemo<number>(() => {
    const leftBalance = walletsList?.find(
      ({ currencyName }) => leftCurrency === currencyName
    )?.balance;

    return !leftCurrency || !leftBalance ? 0 : Number(leftBalance);
  }, [walletsList, leftCurrency]);

  const rightCurrencyBalance = useMemo<number>(() => {
    const rightBalance = walletsList?.find(
      ({ currencyName }) => rightCurrency === currencyName
    )?.balance;
    return !rightCurrency || !rightBalance
      ? 0
      : Number(
          normolizeCurrenciesDecimals(rightBalance, rightCurrency, currencies)
        );
  }, [walletsList, rightCurrency, currencies]);

  const isLimitPriceCorrect = useMemo<boolean>(() => {
    if (!currentExchangeMarket?.ohlcv?.close || !limitPriceValue) {
      return true;
    }
    if (
      Number(limitPriceValue) >
        Number(currentExchangeMarket?.ohlcv?.close) +
          (Number(currentExchangeMarket?.ohlcv?.close) * 10) / 100 ||
      Number(limitPriceValue) <
        Number(currentExchangeMarket?.ohlcv?.close) -
          (Number(currentExchangeMarket?.ohlcv?.close) * 10) / 100
    ) {
      return false;
    }
    return true;
  }, [currentExchangeMarket?.ohlcv?.close, limitPriceValue]);

  const checkIfError = (
    leftValue: number,
    rightValue?: number,
    type?: "fromRange"
  ) => {
    if (hasError) {
      setHasError(null);
    }

    if (!leftValue) {
      return;
    }

    if (Number(leftValue) > leftFullCurrencyBalance) {
      setHasError(t(exceedAmount));
    }

    if (
      operationType === "sell" &&
      Number(leftValue) < Number(currentExchangeMarket?.orderMin)
    ) {
      setHasError(
        `${t(lowerThanMinAmount)} - ${currentExchangeMarket?.orderMin} ${
          currentExchangeMarket?.leftCurrencyName
        }`
      );
    }
    if (
      operationType !== "sell" &&
      rightValue &&
      Number(rightValue) < Number(currentExchangeMarket?.orderMin)
    ) {
      setHasError(
        `${t(lowerThanMinAmount)} - ${currentExchangeMarket?.orderMin} ${
          currentExchangeMarket?.leftCurrencyName
        }`
      );
    }

    if (
      type === "fromRange" &&
      operationType !== "sell" &&
      Number(leftValue) / cost < Number(currentExchangeMarket?.orderMin)
    ) {
      setHasError(
        `${t(lowerThanMinAmount)} - ${currentExchangeMarket?.orderMin} ${
          currentExchangeMarket?.leftCurrencyName
        }`
      );
    }
  };

  const handleChangeValue = (
    value: string,
    shouldNotChangeMain?: "noMainChange",
    isRight?: "isRight"
  ) => {
    const correctInvalidValue = value[0] === "." ? `0${value}` : value;

    if (!isRight && !shouldNotChangeMain) {
      setFullLeftCurrencyValue(correctInvalidValue);
    }

    !isRight
      ? setLeftCurrencyValue(correctInvalidValue)
      : setRightCurrencyValue(correctInvalidValue);

    const newSecondBuyValue =
      operationType === "sell" ? Number(value) * cost : Number(value) / cost;
    const newSecondSellValue =
      operationType === "sell" ? Number(value) / cost : Number(value) * cost;

    if (isNaN(newSecondBuyValue) || isNaN(newSecondSellValue)) {
      !isRight && !shouldNotChangeMain && setFullLeftCurrencyValue("");
      return !isRight ? setRightCurrencyValue("") : setLeftCurrencyValue("");
    }

    const newLeftCurrencyValue = String(
      newSecondSellValue === 0
        ? ""
        : normolizeDecimalsForInputs(
            Number(newSecondSellValue) + Number(newSecondSellValue) * fees,
            rightCurrency!,
            currencies
          )
    );
    const newRightCurrencyValue = String(
      newSecondBuyValue === 0
        ? ""
        : normolizeDecimalsForInputs(
            Number(newSecondBuyValue) - Number(newSecondBuyValue) * fees,
            leftCurrency!,
            currencies
          )
    );

    if (isRight && !shouldNotChangeMain) {
      setFullLeftCurrencyValue(newLeftCurrencyValue);
    }

    !isRight
      ? setRightCurrencyValue(newRightCurrencyValue)
      : setLeftCurrencyValue(newLeftCurrencyValue);

    if (leftCurrency !== "STBU" && leftCurrency !== "GXAG") {
      setCurrentFee(
        !isRight
          ? Number(correctInvalidValue) * fees
          : Number(newSecondSellValue) * fees
      );
    } else {
      setCurrentFee(
        !isRight
          ? Number(newSecondBuyValue) * fees
          : Number(correctInvalidValue) * fees
      );
    }

    if (
      !isMaxValue &&
      ((isRight && Number(newLeftCurrencyValue) >= leftCurrencyBalance) ||
        (!isRight && Number(value) >= leftCurrencyBalance))
    ) {
      setIsMaxValue(true);
    }
    if (
      isMaxValue &&
      ((isRight && Number(newLeftCurrencyValue) < leftCurrencyBalance) ||
        (!isRight && Number(value) < leftCurrencyBalance))
    ) {
      setIsMaxValue(false);
    }

    if (!shouldNotChangeMain) {
      checkIfError(
        isRight ? Number(newLeftCurrencyValue) : Number(value),
        isRight ? Number(value) : Number(newRightCurrencyValue)
      );
    }
  };

  const onCurrencyChange = (option: AvailableTradeOptions) => {
    if (
      (selectType === "leftOptions" && option === leftCurrency) ||
      (selectType !== "leftOptions" && option === rightCurrency)
    ) {
      return;
    }
    setLimitPriceValue("");
    setLeftCurrencyValue("");
    setFullLeftCurrencyValue("");

    if (isMaxValue) {
      setIsMaxValue(false);
    }

    if (selectType === "leftOptions") {
      const newRightCurrencyOprions = markets?.filter(
        ({ leftCurrencyName }) => leftCurrencyName === option
      );

      if (!newRightCurrencyOprions || newRightCurrencyOprions?.length === 0) {
        const newRightCurrencyOprions = markets?.filter(
          ({ rightCurrencyName }) => rightCurrencyName === option
        )!;
        const newRightCurrency =
          newRightCurrencyOprions.find(
            ({ leftCurrencyName }) => leftCurrencyName === rightCurrency
          )?.leftCurrencyName || newRightCurrencyOprions[0].leftCurrencyName;
        setOperationType("buy");
        setRightCurrency(newRightCurrency);
        setLeftCurrency(option);

        return;
      }
      const newRightCurrency =
        newRightCurrencyOprions.find(
          ({ rightCurrencyName }) => rightCurrencyName === rightCurrency
        )?.rightCurrencyName || newRightCurrencyOprions[0].rightCurrencyName;
      setOperationType("sell");
      setRightCurrency(newRightCurrency);
      setLeftCurrency(option);

      return;
    }

    const currentExchangePair = markets?.find(
      ({ leftCurrencyName, rightCurrencyName }) =>
        leftCurrencyName === option && rightCurrencyName === leftCurrency
    );

    if (currentExchangePair) {
      setOperationType("buy");
      setRightCurrency(option);

      return;
    }

    setOperationType("sell");
    setRightCurrency(option);
  };

  const switchTransaction = () => {
    setOperationType(operationType === "sell" ? "buy" : "sell");

    setLeftCurrency(rightCurrency);
    setRightCurrency(leftCurrency);

    if (isMaxValue) {
      setIsMaxValue(false);
    }

    handleChangeValue("");
  };

  useEffect(() => {
    handleChangeValue(fullLeftCurrencyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftCurrency, rightCurrency, cost]);

  useEffect(() => {
    if (!isOpen) {
      handleChangeValue(fullLeftCurrencyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Fade in timeout={500}>
      <ModalContent $isStatic={isStatic}>
        {isStatic && skipConfirm && isLoading && <Preloader isStatic />}
        {!isStatic && <CloseModalButton onClose={onClose} />}
        <ModalHeading>{t("TRADE")}</ModalHeading>
        <InnerContentForm onSubmit={(e) => onSubmit(e)}>
          <TransactionContainer
            $isLimit={tabValue === 1}
            $withTabs
            style={{ paddingTop: "2rem" }}
          >
            {/* {process.env.REACT_APP_ENVIRONMENT === "dev" && (
                <Tabs
                  tabs={tabs}
                  value={tabValue}
                  setValue={setTabValue}
                  tabsLabel="trade tabs"
                  isModal
                />
            )} */}

            {tabValue === 1 && (
              <TransactionGroup>
                <AppText color={colors.gray_500}>{t("LIMIT_PRICE")}</AppText>
                <LimitInput
                  value={limitPriceValue}
                  currencies={currencies}
                  setLimitPriceValue={setLimitPriceValue}
                  currentExchangeMarket={currentExchangeMarket}
                  isLimitPriceCorrect={isLimitPriceCorrect}
                />
              </TransactionGroup>
            )}
            <TransactionGroup>
              <TradeInfo>
                <AppText color={colors.gray_500}>{t("TO_SPEND")}</AppText>
                <AppText fontSize={13} color={colors.gray_500}>
                  {t("BALANCE")} {leftCurrencyBalance} {leftCurrency}
                </AppText>
              </TradeInfo>

              <CurrencyInput
                value={leftCurrencyValue}
                setValue={(value) => handleChangeValue(value)}
                currency={leftCurrency}
                currencies={currencies}
                onClick={() => openSelect("leftOptions")}
                placeholder={
                  operationType === "sell" && currentExchangeMarket?.orderMin
                    ? `min ${currentExchangeMarket.orderMin}`
                    : "0"
                }
              />
            </TransactionGroup>

            <StyledSwitchContainer>
              <SwitchButton $view="transparent" onClick={switchTransaction}>
                <SwitchIcon />
              </SwitchButton>
              <StyledDivider />
            </StyledSwitchContainer>

            <TransactionGroup>
              <TradeInfo>
                <AppText color={colors.gray_500}>{t("TO_RECEIVE")}</AppText>
                <AppText fontSize={13} color={colors.gray_500}>
                  {t("BALANCE")} {rightCurrencyBalance} {rightCurrency}
                </AppText>
              </TradeInfo>

              <CurrencyInput
                value={rightCurrencyValue}
                setValue={(value) =>
                  handleChangeValue(value, undefined, "isRight")
                }
                currency={rightCurrency}
                currencies={currencies}
                onClick={() => openSelect("rightOptions")}
                placeholder={
                  operationType !== "sell" && currentExchangeMarket?.orderMin
                    ? `min ${currentExchangeMarket.orderMin}`
                    : "0"
                }
              />
            </TransactionGroup>

            <RangeInput
              value={leftCurrencyValue}
              setValue={handleChangeValue}
              balance={leftCurrencyBalance}
              setFullValue={setFullLeftCurrencyValue}
              mainCurrency={leftCurrency}
              currencies={currencies}
              checkIfError={checkIfError}
            />
          </TransactionContainer>

          <TradeInfoRows
            leftCurrencyValue={leftCurrencyValue}
            rightCurrencyValue={rightCurrencyValue}
            leftCurrency={leftCurrency}
            rightCurrency={rightCurrency}
            cost={cost}
            currentFee={currentFee}
            currentExchangeMarket={currentExchangeMarket}
            currencies={currencies}
            marketType={marketType}
          />

          <Checkbox
            checked={skipConfirm}
            onClick={() => setSkipConfirm(!skipConfirm)}
            label={t("WITHOUT_CONFIMATION")}
            variant="info"
          />

          {hasError && (
            <ModalError>
              <ModalErrorStatus>
                <AppText fontSize={12} color={colors.error_700}>
                  {t("WARNING")}
                </AppText>
              </ModalErrorStatus>
              <AppText fontSize={12} color={colors.error_700}>
                {hasError}
              </AppText>
            </ModalError>
          )}

          <ModalButton
            disabled={
              Number(leftCurrencyValue) <= 0 ||
              !!hasError ||
              isLoading ||
              (tabValue === 1 && (!limitPriceValue || !isLimitPriceCorrect))
            }
            type="submit"
          >
            {t("SPEND")}{" "}
            {leftCurrency
              ? Number(leftCurrencyValue) > 0
                ? `${normolizeCurrenciesDecimals(
                    leftCurrencyValue,
                    leftCurrency,
                    currencies,
                    "forView"
                  )} ${leftCurrency}`
                : `0 ${leftCurrency}`
              : 0}
          </ModalButton>
        </InnerContentForm>

        <CurrencySelectModal
          onClose={() => setIsSelectOpen(false)}
          isOpen={isSelectOpen}
          selected={selectType === "leftOptions" ? leftCurrency : rightCurrency}
          options={
            selectType === "leftOptions"
              ? leftCurrencyOptions
              : rightCurrencyOptions
          }
          setValue={onCurrencyChange}
          isInsideModal={!isStatic}
        />
      </ModalContent>
    </Fade>
  );
};

export default observer(MainTradeStep);
