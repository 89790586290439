import { Button } from "@mui/material";
import styled from "styled-components";
import { SquereButton } from "components/Buttons";
import { colors } from "helpers/consts";

interface ModalContentInterface {
  $isStatic?: boolean;
}
export const ModalContent: any = styled.div<ModalContentInterface>`
  width: 100%;
  height: 100%;
  padding: 1.6rem 1.6rem 4.8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  ${({ $isStatic }) =>
    $isStatic &&
    `max-width: 60rem; 
      background-color: ${colors.white}; 
      border: 1px solid ${colors.gray_200};
      border-radius: 1.2rem;
      
      .MuiTooltip-tooltip {
        font-size: 1rem;
      }`}
  @media
    (min-width: 36em) {
    padding: 1.6rem 2.4rem 4.8rem;
  }
`;

export const SemiContent = styled.div`
  width: 100%;
  height: calc(92vh - 2px);
  background-color: ${colors.gray_50};
  position: relative;
`;

export const StatusContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 1.6rem 1.6rem 4.8rem;

  @media (min-width: 36em) {
    padding: 3.2rem 2.4rem 4.8rem;
  }
`;

export const SuccessContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
`;

export const SemiContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 0.8rem;
  }
`;

export const StatusInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:first-child) {
    margin-top: 1.6rem;
  }
`;

export const WarningStatusInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InfoColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:first-child) {
    margin-top: 0.8rem;
  }
`;

export const StatusInfoLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.4rem;
  }

  & > svg {
    min-width: 2.4rem;
    max-width: 2.4rem;
    max-height: 2.4rem;
    min-height: 2.4rem;
  }
`;

interface StatusDecorativeInterface {
  $noIcon?: boolean;
  $isTransparent?: boolean;
}
export const StatusDecorative: any = styled.div<StatusDecorativeInterface>`
  width: 100%;
  position: relative;
  margin-bottom: ${({ $noIcon }) => ($noIcon ? "2.4rem" : "7rem")};
  display: flex;
  justify-content: center;
  border-radius: 1.6rem;
  padding: 2rem 1.2rem;
  background-color: ${({ $isTransparent }) =>
    $isTransparent ? "transparent" : colors.gray_100};

  & > img {
    max-width: 20rem;
    border-radius: 1.2rem;
  }

  @media (min-width: 28em) {
    & > img {
      max-width: 22rem;
    }
  }

  @media (min-width: 38em) {
    & > img {
      max-width: 24rem;
    }
  }

  @media (min-width: 48em) {
    padding: 3.2rem 1.6rem;
    & > img {
      max-width: 30rem;
    }
  }
`;

interface StatusIconContainerInterface {
  $isStatic?: boolean;
  $isPending?: boolean;
}
export const StatusIconContainer = styled.div<StatusIconContainerInterface>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $isStatic }) =>
    !$isStatic &&
    "position: absolute; left: 50%; transform: translateX(-50%); bottom: -6rem;"}

  ${({ $isStatic }) =>
    !$isStatic
      ? "min-width: 12rem; max-width: 12rem; max-height: 12rem; min-height: 12rem;"
      : "min-width: 6rem; max-width: 6rem; max-height: 6rem; min-height: 6rem;"}
  border-radius: 50%;

  background-color: ${({ $isStatic, $isPending }) =>
    !$isStatic
      ? $isPending
        ? colors.primary_100
        : colors.success_100
      : colors.primary_100};
  border: ${({ $isStatic }) => (!$isStatic ? "1.8rem" : "0.8rem")} solid
    ${({ $isStatic, $isPending }) =>
      !$isStatic
        ? $isPending
          ? colors.primary_50
          : colors.success_50
        : colors.primary_50};

  & > svg {
    ${({ $isStatic }) =>
      !$isStatic
        ? "min-width: 6rem; max-width: 6rem; max-height: 6rem; min-height: 6rem;"
        : "min-width: 3rem; max-width: 3rem; max-height: 3rem; min-height: 3rem;"}
    ${({ $isStatic }) =>
      $isStatic &&
      `path {
       stroke: ${colors.primary_600};
        }`}
  }
`;

interface TopSemiContentInterface {
  $withInfo?: boolean;
}
export const TopSemiContent = styled.div<TopSemiContentInterface>`
  width: 100%;
  padding: ${({ $withInfo }) =>
    $withInfo ? "1.6rem 2.4rem" : "1.6rem 2.4rem 2rem"};
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.gray_200};
  background-color: ${colors.gray_100};
`;

export const CurrencyOptions = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray_50};
`;

interface CurrencyOptionInterface {
  $isSelected?: boolean;
}
export const CurrencyOption: any = styled(Button)<CurrencyOptionInterface>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.6rem 2.4rem;
  border-bottom: 1px solid ${colors.gray_200};
  background-color: ${({ $isSelected }) =>
    $isSelected ? colors.primary_50 : colors.gray_50};
  color: ${colors.primary_400};
`;
export const CurrencyLine = styled.div`
  width: 86%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const CurrencyColumn = styled.div`
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
`;

export const BeingSelected = styled.div`
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwitchButton = styled(SquereButton)`
  padding: 0.8rem;
  border-radius: 50%;
  min-width: 3.2rem;
  max-width: 3.2rem;
  min-height: 3.2rem;
  max-height: 3.2rem;
  background-color: ${colors.white};
  transform: rotate(90deg);
  z-index: 1;
  border: 1px solid ${colors.primary_600};

  &:hover,
  &:focus {
    border: 1px solid ${colors.primary_600};
  }

  @media (min-width: 36em) {
    transform: rotate(0);
    grid-area: switch;
  }
`;

export const StyledSwitchContainer = styled.div`
  width: 100%;
  position: relative;
  grid-area: switch;
  display: flex;
  justify-content: center;
`;
export const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  z-index: 0;
  background-color: ${colors.gray_100};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface InfoRowsInterface {
  $maxWidth?: string;
}
export const InfoRows = styled.div<InfoRowsInterface>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ $maxWidth }) =>
    $maxWidth &&
    `
    max-width: 51.2rem;
    @media (min-width: 48em) {
       max-width: ${$maxWidth};
       min-width: ${$maxWidth};
    }`}
`;

export const InnerContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 0.8rem;
  }
`;

export const InnerContentForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 0.8rem;
  }
`;

export const ConfirmBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3.2rem 1.6rem 1.6rem;
  border-radius: 1.6rem;
  border: solid 1px ${colors.gray_100};
  background-color: ${colors.gray_50};

  & > *:not(div):not(:first-child) {
    margin-top: 0.4rem;
  }

  & > svg {
    min-width: 5.6rem;
    max-width: 5.6rem;
    max-height: 5.6rem;
    min-height: 5.6rem;
    margin-bottom: 0.4rem;
  }
`;

export const ConfirmInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1.6rem;
  margin-top: 2.2rem;
  border-top: solid 1px ${colors.gray_100};

  > p {
    max-width: 30rem;
    text-align: center;
  }
`;

interface HeadingInterface {
  $centered?: boolean;
  $capitalized?: boolean;
}
export const ModalHeading = styled.h2<HeadingInterface>`
  width: 100%;
  height: 4.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${colors.gray_900};
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  ${({ $centered }) => $centered && "justify-content: center;"}
  ${({ $capitalized }) => $capitalized && "text-transform: capitalize;"}
`;

export const ModalSemiHeading = styled.h3`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${colors.gray_900};
  margin: 3rem 0 2rem;
`;

interface TransactionContainerInterface {
  $isLimit?: boolean;
  $withTabs?: boolean;
}
export const TransactionContainer = styled.div<TransactionContainerInterface>`
  width: 100%;
  padding: ${({ $withTabs }) =>
    $withTabs ? "0.6rem 1.6rem 1.6rem" : "1.6rem"};
  border-radius: 1.6rem;
  border: 1px solid ${colors.gray_100};
  background-color: ${colors.gray_50};

  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:first-child) {
    margin-top: 1.2rem;
  }

  & ${StyledSwitchContainer} svg {
    transform: rotate(90deg);
  }
`;

interface MainContainerInterface {
  $customMargin?: number;
  $padding?: string;
}
export const MainContainer = styled.div<MainContainerInterface>`
  width: 100%;
  padding: ${({ $padding }) => ($padding ? `${$padding}` : "1.6rem")};
  border-radius: 1.6rem;
  border: 1px solid ${colors.gray_100};
  background-color: ${colors.gray_50};

  ${({ $customMargin }) =>
    $customMargin
      ? `& > *:not(:first-child) {
    margin-top: ${$customMargin / 10}rem;
  }`
      : `& > *:not(:first-child) {
    margin-top: 2.4rem;
  }`};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface GridArea {
  $area?: string;
}
export const TransactionGroup = styled.div<GridArea>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ $area }) => $area && `grid-area: ${$area};`}

  & > *:not(:first-child) {
    margin-top: 0.4rem;
  }
`;

export const TransactionLine = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.4rem;
  }
`;

export const InfoLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 0.4rem;
  }

  & > svg {
    max-width: 2rem;
    margin-bottom: 0.2rem;

    path {
      stroke: ${colors.primary_600};
    }
  }
`;

interface ModalErrorInterface {
  $darkTheme?: boolean;
}
export const ModalError = styled.div<ModalErrorInterface>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.4rem 1.2rem;
  background-color: ${({ $darkTheme }) =>
    $darkTheme ? colors.gray_900 : colors.error_50};
  border-radius: 1.6rem;

  & > *:not(:first-child) {
    margin-left: 0.8rem;
  }
`;

export const ModalErrorStatus = styled.div<ModalErrorInterface>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.8rem;
  background-color: ${({ $darkTheme }) =>
    $darkTheme ? colors.black : colors.white};
  border-radius: 1.6rem;
`;

export const ModalErrorInfo = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  & > *:not(:first-child) {
    margin-top: 0.4rem;
  }
`;

interface QrContainerInterface {
  $noBorder?: boolean;
}
export const QrContainer = styled.div<QrContainerInterface>`
  width: 100%;
  margin: 0.8rem 0;
  padding-top: 1.6rem;
  ${({ $noBorder }) =>
    !$noBorder && `border-top: 1px solid ${colors.gray_100};`}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface QrZoneInterface {
  $isLarge?: boolean;
  $isBlured?: boolean;
}
export const QrZone = styled.div<QrZoneInterface>`
  background-color: ${colors.white};
  padding: 0.8rem;
  max-width: ${({ $isLarge }) => ($isLarge ? "20" : "16rem")};
  max-height: ${({ $isLarge }) => ($isLarge ? "20" : "16rem")};
  border-radius: 0.4rem;
  border: 1px solid ${colors.gray_100};
  ${({ $isBlured }) => $isBlured && "filter: blur(8px);"}
`;

export const WarningsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 0.8rem;
  }
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  padding: 1.6rem 1.6rem 4.8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (min-width: 36em) {
    padding: 1.6rem 2.4rem 4.8rem;
  }
`;

interface InputContainerInterface {
  $noMargin?: boolean;
  $marginTop?: boolean;
}
export const InputContainer = styled.div<InputContainerInterface>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ $noMargin }) => !$noMargin && "margin-bottom: 1.6rem;"}
  ${({ $marginTop }) => $marginTop && "margin-top: 1.6rem;"}

  & > *:not(:first-child) {
    margin-top: 0.6rem;
  }
`;

export const InputLineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  & > *:not(:first-child) {
    margin-left: 0.6rem;
  }
`;

export const TradeInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:not(:first-child) {
    margin-left: 0.4rem;
  }
`;

export const AssetsChooseInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem 14% 0 6.8rem;

  & > *:not(:first-child) {
    margin-left: 0.4rem;
  }
`;

export const UnstakeColumn = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const UnstakeLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0.6rem 1rem;
  border-radius: 1.2rem;
  border: solid 2px ${colors.gray_300};

  & > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const TwoFaQRContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.2rem;
  background-color: ${colors.gray_100};
  padding: 0 1.2rem 2rem;
`;

export const ItezContainer = styled.div`
  width: 100%;
  padding: 1.6rem;
  border-radius: 1.6rem;
  border: 1px solid ${colors.gray_100};
  background-color: ${colors.gray_50};
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:first-child) {
    margin-top: 2.4rem;
  }
`;

interface InfoDecorativeInterfave {
  $isAlternative?: boolean;
}
export const InfoDecorative = styled.div<InfoDecorativeInterfave>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 1.6rem;
  padding: 3.2rem 1.6rem 1.6rem;
  background-color: ${colors.primary_25};
  border: 1px solid ${colors.primary_100};

  ${({ $isAlternative }) =>
    $isAlternative &&
    `
  & > div > img {
       max-width: 100%;
       border-radius: 1.2rem;
    }
      @media (min-width: 48em) {
  & > div > img {
       max-width: 49.2rem;
    }
  }
  `}
`;

export const PaddingContainer = styled.div`
  width: 100%;
  padding: 0 0.4rem;
`;
